import React, { useEffect, useState } from "react"
import styled from "styled-components"
import styles from "./LandscapeDisabled.module.css"
import { fade, responsive } from "../style-utils"
import { Text } from "rebass"


export const LandscapeDisabledStyled = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;

    width: 100vw;
    height: 100%;
    font-family: IBM Plex Sans;
    background-color: rgb(255,255,255);
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 6vh;
    z-index: 9999;

    ${props => fade(props.visible, `.4s`)}

    ${responsive.mobile`
        display: flex;
    `}
`;

export const LandscapeDisabledImg = () => <div className={styles.landscapeImg} />;
;

export const LandscapeDisabled = () => {
    const [landscape, setLandscape] = useState(false);

    const detectLandscape = () => {
        const isLandscape = window.matchMedia("(orientation: landscape)").matches;
        setLandscape(isLandscape);
    };

    useEffect(() => {
        detectLandscape();
        window.addEventListener('resize', detectLandscape);
        return () => {
            window.removeEventListener('resize', detectLandscape);
        };
    }, [detectLandscape]);

    return (
        <LandscapeDisabledStyled visible={landscape}>
            <LandscapeDisabledImg />
            <Text
                fontSize={'1.5rem'}
                fontFamily="IBM Plex Sans"
                textAlign="left"
                fontWeight="400"
            >
                Please turn your device
            </Text>
        </LandscapeDisabledStyled>
    );
}
