import React, { useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import ReactFullpage from "@fullpage/react-fullpage"
import { createGlobalStyle, ThemeProvider } from "styled-components"

import theme from "./theme"
import DeviceContext from "../context/DeviceContext"
import { LandscapeDisabled } from "./LandscapeDisabled"
import SiteNavigation, { NAVIGATION_ITEMS } from "./navigation"

const GlobalStyle = createGlobalStyle`
* { box-sizing: border-box; }

html {
  scroll-behavior: smooth;
  
  @media (max-width: 480px) {
    scroll-behavior: unset;
  }
}

body {
  margin: 0;
  font-size: 62.5%;
  line-height: 1.5!important;
  overflow-x: hidden;
  
  
  .rec-slider-container {
    margin: 0;
  }
  
  .rec-pagination {
    display: none;
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
  }
}

    
@media (max-width: 1024px) {
  body {
    font-size: 15px;
  }
}

@media (max-width: 640px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  body {
    font-size: 12px;
  }
}

h2 {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  font-size: 3rem;
  letter-spacing: -0.01em;
}

h3 {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.25rem;
  letter-spacing: -0.01em;
}
`



const FullPageLayout = ({ children }) => {
  const fpRef = useRef()

  const rebuildFP = () => {
    if (fpRef.current) {
      fpRef.current.fullpageApi.reBuild()
    }
  }

  useEffect(() => {
    window.addEventListener("resize", rebuildFP)
    return () => {
      window.removeEventListener("resize", rebuildFP)
    }
  }, [rebuildFP])

  return (
    <ReactFullpage
      animateAnchor
      autoScrolling
      fitToSection
      navigation
      ref={fpRef}
      menu="#menu"
      lockAnchors={false}
      fixedElements={["vertical-logo"]}
      fitToSectionDelay={100}
      scrollingSpeed={1000}
      anchors={NAVIGATION_ITEMS}
      controlArrows={false}
      slidesNavigation
      slidesNavPosition="bottom"
      licenseKey="31E6153C-07CC47CF-9406FA64-80D5705A"
      verticalCentered={false}
      loopHorizontal={false}
      render={() => <ReactFullpage.Wrapper>{children}</ReactFullpage.Wrapper>}
    />
  )
}

FullPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Layout = ({children}) => {
  const device = useContext(DeviceContext);

  if (device.isMobile) {
    return (
      <>
        <LandscapeDisabled />
        <FullPageLayout>{children}</FullPageLayout>
      </>
    )
  }

  return (
    <>{children}</>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

const SectionsLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyle />
          <SiteNavigation />
          <Layout>{children}</Layout>
        </React.Fragment>
    </ThemeProvider>
  )
}
SectionsLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SectionsLayout
