import React from "react"
import SEO from "../components/seo"

import SectionsLayout from "../components/SectionsLayout"

import Services from "./Services"
import Home from "./Home"
import About from "./About"
import Live from "./Live"
import Contacts from "./Contacts"
import { DeviceProvider } from "../context/DeviceContext"

const IndexPage = () => (
  <>
    <DeviceProvider>
      <SectionsLayout>
        <SEO
          title="Geekchester | We engineer great customer experiences and help others on their way"
          keywords={[
            `Customer Experience Engineering`,
            `Software`,
            `App`,
            `Development`,
            `Tarkvara arendus`,
            `Software Engineering`,
          ]}
        />
        <Home />
        <Services />
        <About />
        <Live />
        <Contacts />
      </SectionsLayout>
    </DeviceProvider>
  </>
)
export default IndexPage
