import React, { useState } from "react"
import styled from "styled-components"
import Menu from "react-hamburger-menu"
import classNames from "classnames"

import gcLogoImg from "../images/gc_logo.svg"
import { Text } from "rebass"

export const NAVIGATION_ITEMS = ["home", "services", "about", "live", "contact"]

const NavigationWrapper = styled.div`
    position: fixed;
    top: 1.25rem;
    z-index: 1000;
    
    .hamburger-menu {
      display: none;
    }
    
    @media (max-width: 480px) {
      display: none;
    }
    
    @media (max-width: 768px) {
      top: 0;
      right: 0;
      width: 100%;
      padding: 2rem;
      
      .hamburger-menu {
        display: block;
        margin-left: auto;
        cursor: pointer;
      }
    }
`

const BrandLogo = styled.div`
  position: absolute;
  z-index: 1001;
  left: -1rem;
  top: 127px;
  opacity: 1;
  background: url(${gcLogoImg}) no-repeat;
  transform: rotate(270deg);
  min-height: 26px;
  min-width: 165px;
  
  @media (max-width: 768px) {
    position: fixed;
    background-size: contain;
    transform: inherit;
    display: block;
    left: inherit;
    top: inherit;
    margin-top: 2rem;
    margin-left: 2rem;
  }
`
const HamburgerList = styled.div`
  position: fixed;
  min-height: 100vh;
  overflow: hidden;
  top: 5rem;
  right: -150px;
  display: none;
  text-align: right;
  padding-right: 10px;
  
  transition: right 0.25s ease-in-out;
  
  &.opened {
    right: 0;
  }
  
  a {
    display: inline-block;
    border: 2px solid black;
    margin-bottom: 5px;
    background: #FFF;
  }
  
  @media (max-width: 768px) {
    display: block;
  }
`

const HeaderMenu = styled.ul`
  display: flex;
  background: #FFF;
  border: 2px solid black;
    
  li {
    font-size: 1.5rem;
  }
  
  @media (max-width: 768px) {
    display: none;
  }
`

const HamburgerMenu = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  return (
    <>
      <Menu
        className="hamburger-menu"
        isOpen={hamburgerOpen}
        width={25}
        height={18}
        animationDuration={0.2}
        menuClicked={() => setHamburgerOpen(!hamburgerOpen)}
      />

      <HamburgerList className={classNames({ opened: hamburgerOpen })}>
        {
          NAVIGATION_ITEMS.map(item => (
            <>
              <a href={`#${item}-anchor`} key={item}>
                <Text
                  color="black"
                  fontSize="1.5rem"
                  fontFamily="IBM Plex Sans"
                  textAlign="center"
                  fontWeight="400"
                  padding="5px"
                  css={{
                    textTransform: "uppercase",
                  }}
                >
                  {item}
                </Text>
              </a>
              <br/>
            </>
          ))
        }
      </HamburgerList>
    </>
  )
}

const Header = () => {
  return (
    <NavigationWrapper>
      <HeaderMenu id="menu" className="noselect">

        {
          NAVIGATION_ITEMS.map(item => (
            <a href={`#${item}-anchor`}>
              <li data-menuanchor={item} key={item}>
                {item}
              </li>
            </a>
          ))
        }

      </HeaderMenu>

      <HamburgerMenu/>
    </NavigationWrapper>
  )
}

const SiteNavigation = () => {
  return (
    <>
      <BrandLogo/>
      <Header/>
    </>
  )
}

export default SiteNavigation