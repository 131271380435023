const pinkPalette = {
  background: "#FFFFFF",
  backgroundDark: "#f0e6f6",

  primary: "#7c37ad",
  primaryLight: "#ae66df",
  primaryDark: "#4b007d",
  primaryDarkLight: "#4b007d",

  secondary: "#ff4081",
  secondaryLight: "#ff79b0",
  secondaryDark: "#c60055",

  solidDark: "#000000",
  solidDarkLight: "#808080",

  lightWhite: "#fcfcfb",
  gcOrange: "#F6682F",
}

// const fontSizes = {
//   tiny: '20px',
//   medium: '14px',
//   large: '24px',
// };

module.exports = {
  colors: pinkPalette,
  // fontSizes,
}
